
export const DASHBOARD_TABLE_HEAD = [
    { id: "View", label: "View", alignRight: false },
    { id: "Name", label: "Name", alignRight: false },
    { id: "phone Number", label: "Phone Number", alignRight: false },
    // { id: "website", label: "Website", alignRight: false },
    { id: "Add Image", label: "Add Image", alignRight: false },
    { id: "edit", label: "Edit", alignRight: false },
    { id: "delete", label: "Delete", alignRight: false },
    { id: "Active", label: "Active", alignRight: false },
  ];